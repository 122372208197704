<template>
  <div class="selectFile">
    <div>
      <van-uploader
        :after-read="afterReadFile"
        v-if="!isFinishUpload"
        accept="*"
      >
        <van-button icon="plus" type="primary" size="mini" block
          >上传文件</van-button
        >
      </van-uploader>
      <div class="fileBox" v-if="isFinishUpload">
        <van-image
          width="7vw"
          height="7vw"
          :src="require('@/assets/images/word.png')"
        />
        <div class="fileName">
          {{ fileName }}
        </div>
      </div>
      <div class="printType">
        <van-button
          plain
          hairline
          type="danger"
          size="mini"
          @click="printAndCreateCase"
          >打印并创建学案</van-button
        >
        <van-button plain hairline type="warning" size="mini" @click="printFile"
          >仅打印</van-button
        >
        <van-button
          plain
          hairline
          type="warning"
          size="mini"
          @click="createCase"
          >仅创建学案</van-button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import { uploadFile } from "@/service/upload.js";

const router = useRouter();
let emit = defineEmits(["changeActive", "changeFile", "changeIsPrint"]);

let fileName = ref("");
let isFinishUpload = ref(false);
const afterReadFile = async (file) => {
  let formData = new FormData();
  formData.append("file", file.file);
  let res = await uploadFile(formData);
  if (res) {
    isFinishUpload.value = true;
    fileName.value = res.data.file.name;
    emit("changeFile", res.data);
    localStorage.setItem(
      "file",
      JSON.stringify({
        fileName: res.data.file.name,
        filePath: res.data.file.url,
        ext: res.data.file.suffix,
      })
    );
  }
};

// 打印并创建学案
const printAndCreateCase = () => {
  if (isFinishUpload.value) {
    router.push({ path: "/teacher/printFile/settingInfo" });
    localStorage.setItem("active", 1);
    localStorage.setItem("type", 1);
    emit("changeActive");
  } else {
    Toast.fail("请先上传文档");
  }
};

// 仅打印
const printFile = () => {
  if (isFinishUpload.value) {
    router.push({ path: "/teacher/PrintReview" });
    localStorage.setItem("type", 2);
  } else {
    Toast.fail("请先上传文档");
  }
};

// 仅创建学案
const createCase = () => {
  if (isFinishUpload.value) {
    localStorage.setItem("type", 3);
    localStorage.setItem("active", 1);
    router.push({ path: "/teacher/printFile/settingInfo" });
    emit("changeActive", 1);
    emit("changeIsPrint", "创建中");
  } else {
    Toast.fail("请先上传文档");
  }
};
</script>

<style lang="less" scoped>
.selectFile {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 50px;

  :deep(.van-button--block) {
    width: 200px;
  }
  .printType {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  .fileBox {
    display: flex;
    align-items: center;
    font-size: 10px;
    border: 1px solid #d7d7d7;
    padding: 5px;
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;

    .fileName {
      margin-left: 10px;
    }
  }
}
</style>
